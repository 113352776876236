$(document).ready(function () {
    var ctrl = new ScrollMagic.Controller();

    if ($('.fadeInUp, .fadeIn').length > 0) {
        $('.fadeInUp, .fadeIn').each(function (index) {
            var fadedElement = this;

            var scene = new ScrollMagic.Scene({
                    triggerElement: fadedElement,
                    offset: -$(window).height() * 0.4,
                    duration: '100%',
                    triggerHook: 0.6,
                    reverse: true,
                })
                .on("enter leave", function (e) {
                    $(fadedElement).addClass('animate');
                })
                .addTo(ctrl);
        });
    }

    $('body').on('click', '.header-burger', function (e) {
        e.preventDefault();
        $(this).toggleClass('header-burger--open');
        $('.mobile-pane').toggleClass('mobile-pane--open');
    });

    $('body').on('click', '.scrollTo', function (e) {
        e.preventDefault();
        var href = $(this).attr('href');

        $('body,html').animate({
            scrollTop: $(href).offset().top - $('.header').innerHeight(),
        }, 800);

        if ($('.mobile-pane').hasClass('mobile-pane--open')) {
            $('.header-burger').removeClass('header-burger--open');
            $('.mobile-pane').removeClass('mobile-pane--open');
        }

    });

    $('body').on('click', '.scroll-top', function (e) {
        e.preventDefault();

        $('body,html').animate({
            scrollTop: 0,
        }, 800);

    });

    $("input[name=phone]").inputmask({
        mask: "+7 (999) 999 99 99",
        showMaskOnHover: false,
    });


    var scrollTop = $(window).scrollTop();

    if (scrollTop > 0) {
        $('.header').addClass('header--fixed');
    } else {
        $('.header').removeClass('header--fixed');
    }

    $(window).on('scroll', function () {
        var scrollTop = $(this).scrollTop();

        if (scrollTop > 0) {
            $('.header').addClass('header--fixed');
        } else {
            $('.header').removeClass('header--fixed');
        }

        if (scrollTop > $('.main-banner').outerHeight()) {
            $('.scroll-top').addClass('scroll-top--show');
        } else {
            $('.scroll-top').removeClass('scroll-top--show');
        }

    });

    $('.c-select').each(function () {
        var placeholder = $(this).data('placeholder');
        var icon = $(this).data('icon');

        $(this).customSelect({
            includeValue: true,
            //placeholder: '<div class="custom-select__placeholder">' + placeholder + '</div>'
        });

        $(this).next().prepend('<svg width="16" height="10" class="custom-select__arrow" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 2L8 8L2 2" stroke="#BDBDBD" stroke-width="2" stroke-linecap="square"/></svg>');
    });

    /* баловство */

    var myImage = document.querySelector('img');

    myImage.onclick = function () {
        var mySrc = myImage.getAttribute('src');
        if (mySrc === 'img/firefox-icon.png') {
            myImage.setAttribute('src', 'img/firefox2.png');
        } else {
            myImage.setAttribute('src', 'img/firefox-icon.png');
        }
    }




    /* CALCULATE */

    var priceSet = function (data) {
        var price = Number.prototype.toFixed.call(parseFloat(data) || 0, 0),
            price_sep = price.replace(/(\D)/g, ","),
            price_sep = price_sep.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");

        return price_sep;
    };


    var prodObj = {
        "1": {
            "name": "РВС-5000",
            "price": 0,
            "items": {
                "1": {
                    "name": "Регистры и утепление 50 грд. С",
                    "price": 2300000
                },
                "2": {
                    "name": "Регистры и утепление 170 грд. С",
                    "price": 3270000
                },
            },
            "itemsTermal": {
                "1": {
                    "name": "Теплоизоляция 100мм",
                    "price": 4500000
                },
                "2": {
                    "name": "Теплоизоляция 200мм",
                    "price": 5800000
                },
            },

            "project": {
                "price": 250000
            },
            "anticor": {
                "price": 2200000
            },
            "made": {
                "price": 14200000
            },
            "montage": {
                "price": 6900000
            },
            "fnd": {
                "price": 5700000
            }

        },
        "2": {
            "name": "РВС-3000",
            "price": 0,
            "items": {
                "1": {
                    "name": "Регистры и утепление 50 грд. С",
                    "price": 2050000
                },
                "2": {
                    "name": "Регистры и утепление 170 грд. С",
                    "price": 2950000
                },
            },
            "itemsTermal": {
                "1": {
                    "name": "Теплоизоляция 100мм",
                    "price": 3600000
                },
                "2": {
                    "name": "Теплоизоляция 200мм",
                    "price": 4600000
                },
            },

            "project": {
                "price": 230000
            },
            "anticor": {
                "price": 1600000
            },
            "made": {
                "price": 10684000
            },
            "montage": {
                "price": 5700000
            },
            "fnd": {
                "price": 4500000
            }

        },
        "3": {
            "name": "РВС-2000",
            "price": 0,
            "items": {
                "1": {
                    "name": "Регистры и утепление 50 грд. С",
                    "price": 1900000
                },
                "2": {
                    "name": "Регистры и утепление 170 грд. С",
                    "price": 2700000
                },
            },
            "itemsTermal": {
                "1": {
                    "name": "Теплоизоляция 100мм",
                    "price": 2500000
                },
                "2": {
                    "name": "Теплоизоляция 200мм",
                    "price": 3200000
                },
            },
            "project": {
                "price": 200000
            },
            "anticor": {
                "price": 1340000
            },
            "made": {
                "price": 7260000
            },
            "montage": {
                "price": 4900000
            },
            "fnd": {
                "price": 3700000
            }
        },
        "4": {
            "name": "РВС-1000",
            "price": 0,
            "items": {
                "1": {
                    "name": "Регистры и утепление 50 грд. С",
                    "price": 1300000
                },
                "2": {
                    "name": "Регистры и утепление 170 грд. С",
                    "price": 1950000
                },
            },
            "itemsTermal": {
                "1": {
                    "name": "Теплоизоляция 100мм",
                    "price": 1800000
                },
                "2": {
                    "name": "Теплоизоляция 200мм",
                    "price": 2150000
                },
            },
            "project": {
                "price": 150000
            },
            "anticor": {
                "price": 800000
            },
            "made": {
                "price": 4340000
            },
            "montage": {
                "price": 3400000
            },
            "fnd": {
                "price": 3100000
            }
        },
        "5": {
            "name": "РВС-500",
            "price": 0,
            "items": {
                "1": {
                    "name": "Регистры и утепление 50 грд. С",
                    "price": 960000
                },
                "2": {
                    "name": "Регистры и утепление 170 грд. С",
                    "price": 1370000
                },
            },
            "itemsTermal": {
                "1": {
                    "name": "Теплоизоляция 100мм",
                    "price": 1200000
                },
                "2": {
                    "name": "Теплоизоляция 200мм",
                    "price": 1500000
                },
            },
            "project": {
                "price": 120000
            },
            "anticor": {
                "price": 560000
            },
            "made": {
                "price": 2640000
            },
            "montage": {
                "price": 3200000
            },
            "fnd": {
                "price": 1500000
            }
        },
        "6": {
            "name": "РВС-200",
            "price": 0,
            "items": {
                "1": {
                    "name": "Регистры и утепление 50 грд. С",
                    "price": 750000
                },
                "2": {
                    "name": "Регистры и утепление 170 грд. С",
                    "price": 1070000
                },
            },
            "itemsTermal": {
                "1": {
                    "name": "Теплоизоляция 100мм",
                    "price": 800000
                },
                "2": {
                    "name": "Теплоизоляция 200мм",
                    "price": 1050000
                },
            },
            "project": {
                "price": 100000
            },
            "anticor": {
                "price": 450000
            },
            "made": {
                "price": 1800000
            },
            "montage": {
                "price": 2100000
            },
            "fnd": {
                "price": 1100000
            }
        }
    };

    var container = $('.construct__form');

    var rvsOptions = '';

    $.each(prodObj, function (i, v) {
        rvsOptions += '<option value="' + i + '">' + v.name + '</option>';
    });

    container.find('select[name=rvs]').html(rvsOptions).customSelect('reset');

    getHeat(container.find('select[name=rvs]').val(), container);
    getTermal(container.find('select[name=rvs]').val(), container);

    $('body').on('change', 'select[name=rvs]', function (e) {
        var val = $(this).val();

        getHeat(val, container);
        getTermal(val, container);

    });

    $('body').on('change', 'select[name=termal], select[name=heat], input[name = project], input[name = anticor], input[name = made], input[name=mon], input[name=fnd]', function (e) {
        calculate(container);
    });


    function getHeat(val, calcContainer) {
        var items = prodObj[val].items;

        var heatOptions = '';

        heatOptions = '<option value="-1">Без подогрева</option>';

        $.each(items, function (i, v) {
            heatOptions += '<option value="' + i + '">' + v.name + '</option>';
        });

        calcContainer.find('select[name=heat]').html(heatOptions).customSelect('reset');

        calculate(calcContainer);
    }

    function getTermal(val, calcContainer) {
        var itemsTermal = prodObj[val].itemsTermal;

        var termalOptions = '';

        termalOptions = '<option value="-1">Без теплоизоляции</option>';

        $.each(itemsTermal, function (i, v) {
            termalOptions += '<option value="' + i + '">' + v.name + '</option>';
        });

        calcContainer.find('select[name=termal]').html(termalOptions).customSelect('reset');

        calculate(calcContainer);
    }

    function calculate(calcContainer) {
        var rvsVal = calcContainer.find('select[name=rvs]').val(),
            rvsPrice = prodObj[rvsVal].price,
            termalVal = calcContainer.find('select[name=termal]').val(),
            termalPrice = termalVal != -1 ? prodObj[rvsVal].itemsTermal[termalVal].price : 0,
            fullPrice = 0,
            heatVal = calcContainer.find('select[name=heat]').val(),
            heatPrice = heatVal != -1 ? prodObj[rvsVal].items[heatVal].price : 0,
            fullPrice = 0,
            monVal = calcContainer.find('input[name=mon]'),
			monPrice = monVal.is(':checked') ? prodObj[rvsVal].montage.price : 0,
				
            anticorVal = calcContainer.find('input[name=anticor]'),
            anticorPrice = anticorVal.is(':checked') ? prodObj[rvsVal].anticor.price : 0,

            madeVal = calcContainer.find('input[name=made]'),
            madePrice = madeVal.is(':checked') ? prodObj[rvsVal].made.price : 0,

            projectVal = calcContainer.find('input[name=project]'),
            projectPrice = projectVal.is(':checked') ? prodObj[rvsVal].project.price : 0,

            fndVal = calcContainer.find('input[name=fnd]'),
            fndPrice = fndVal.is(':checked') ? prodObj[rvsVal].fnd.price : 0;

        fullPrice = rvsPrice + heatPrice + termalPrice + madePrice + anticorPrice + monPrice + projectPrice + fndPrice;

        $('.construct__sum span').text(priceSet(fullPrice) + ' руб.');
    }

    $('.form').each(function () {
        $(this).validate({
            ignore: '',
            errorClass: "_warning",
            rules: {
                fio: {
                    required: true,
                    minlength: 2,
                    maxlength: 100,
                },
                phone: {
                    required: true,
                },
                personal: {
                    required: true,
                }
            },
            messages: {
                fio: {
                    required: "",
                    minlength: "",
                    maxlength: "",
                },
                phone: {
                    required: "",
                },
                personal: {
                    required: "",
                },
            },
            errorPlacement: function (error, element) {},
            submitHandler: function (form) {

                var formData = new FormData($(form).get(0));

                if ($(form).hasClass('construct__form')) {
                    var rvsVal = prodObj[$(form).find('select[name=rvs]').val()].name,
                        heatVal = $(form).find('select[name=heat]').val() != -1 ? prodObj[$(form).find('select[name=rvs]').val()].items[$(form).find('select[name=heat]').val()].name : '',
                        termalVal = $(form).find('select[name=termal]').val() != -1 ? prodObj[$(form).find('select[name=rvs]').val()].itemsTermal[$(form).find('select[name=termal]').val()].name : '',
                        projectVal = $(form).find('input[name=project]').is(':checked') ? '– Проект' : '',
                        anticorVal = $(form).find('input[name=anticor]').is(':checked') ? '– Антикоррозийка' : '',
                        madeVal = $(form).find('input[name=made]').is(':checked') ? '– Изготовление' : '',
                        monVal = $(form).find('input[name=mon]').is(':checked') ? '– Монтаж' : '',
                        fndVal = $(form).find('input[name=fnd]').is(':checked') ? '– Фундамент' : '',
                        sumVal = $(form).find('.construct__sum').text();

                    formData.append('rvs', rvsVal);
                    formData.append('termal', termalVal);
                    formData.append('heat', heatVal);
                    formData.append('project', projectVal);
                    formData.append('anticor', anticorVal);
                    formData.append('made', madeVal);
                    formData.append('mon', monVal);
                    formData.append('fnd', fndVal);
                    formData.append('sum', sumVal);
                }

                $.ajax({
                    type: "POST",
                    url: "mail.php",
                    dataType: 'json',
                    contentType: false,
                    processData: false,
                    data: formData,
                    success: function (data) {
                        $(form).find('.response').remove();
                        if (data.status == 'error') {
                            $.fancybox.close('all');
                            $.fancybox.open('<div class="popup--success popup"><div class="popup__header">' + data.msg + '</div></div>', {
                                touch: false,
                            });
                            $(form)[0].reset();
                        } else {
                            $.fancybox.close('all');
                            $.fancybox.open('<div class="popup--success popup"><div class="popup__header">' + data.msg + '</div></div>', {
                                touch: false,
                            });
                            $(form)[0].reset();
                        }
                    }
                });

            }

        });

    });

});